.modal{
    width: clamp(20%, 600px, 70%);
    height: min(50%, 300px);

    margin: auto;
    padding: 0 2rem;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color:white;
    text-align: center;
    z-index: 1;
    
}

.modal > p {
    margin-top: 20px;
}

.modal > h1{
    margin-top: 30px;
}

.modal > button{
    
    background: black;
    margin-top: 20px;
    font-size: 15px;
    cursor: pointer;
    color: white;
    border-radius: 10px;
    width: 100px;
    height: 40px;
}