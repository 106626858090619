/* .loading {
    display: flex;
    justify-content: center;
  }
  
  .loading::after {
    content: '';
    width: 50px;
    height: 50px;
    border: 10px solid #dddddd;
    border-top-color: #009579;
    border-radius: 50%;
    animation: loading 1s linear infinite;
  }

  @keyframes loading {
    to {
      transform: rotate(1turn);
    }
  
    /* heart beat effect
     to {
         opacity: 1;
      transform: scale(1.75);
    }
    
    
  }


   */


.loading
{
  position:relative;
  
  width:110px;
  height:110px;
  background:transparent;
  border:3px solid #3c3c3c;
  border-radius:50%;
  text-align:center;
  line-height:115px;
  font-family:sans-serif;
  font-size:13px;
  color:#f5f5f5;
  letter-spacing:4px;
  text-transform:uppercase;
  text-shadow:0 0 10px #f5f5f5;
  box-shadow:0 0 20px rgba(0,0,0,.5);

}
.loading:before
{
  content:'';
  position:absolute;
  top:-3px;
  left:-3px;
  width:100%;
  height:100%;
  border:3px solid transparent;
  border-top:3px solid #f5f5f5;
  border-right:3px solid #f5f5f5;
  border-radius:50%;
  animation:animateC 2s linear infinite;
}
span
{
  display:block;
  position:absolute;
  top:calc(50% - 2px);
  left:50%;
  width:50%;
  height:4px;
  background:transparent;
  transform-origin:left;
  animation: animate 2s linear infinite;
}
span:before
{
  content:'';
  position:absolute;
  width:16px;
  height:16px;
  border-radius:50%;
  background:#f5f5f5;
  top:-6px;
  right:-8px;
  box-shadow:0 0 20px #f5f5f5;
}
@keyframes animateC
{
  0%
  {
    transform:rotate(0deg);
  }
  100%
  {
    transform:rotate(360deg);
  }
}
@keyframes animate
{
  0%
  {
    transform:rotate(45deg);
  }
  100%
  {
    transform:rotate(405deg);
  }
}




@media screen and (max-width: 800px){
      
.loading
{
  position:relative;
  
  /* transform:translate(-50%,-50%);  this was interfering witht the centering */
  width:100px;
  height:100px;
  background:transparent;
  border:3px solid #3c3c3c;
  border-radius:50%;
  text-align:center;
  line-height:105px;
  font-family:sans-serif;
  font-size:11px;
  color:#f5f5f5;
  letter-spacing:4px;
  text-transform:uppercase;
  text-shadow:0 0 10px #f5f5f5;
  box-shadow:0 0 20px rgba(0,0,0,.5);

}
    
    
}