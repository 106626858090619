.overlay{
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: scroll;
}

.modalContainer{
    max-width: 600px;
    width: 100%;
    height: 300px;
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.75);
    border: solid;
    border-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    transition: all 0.5s ease;
    
}

.closeBtn{

    position: fixed;
    top: 8px;
    right: 8px;
}

.modalContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modalContent > p{

    margin-top: 10px;
}


@media screen and (max-width: 800px){

    .modalContainer{
        max-width: 350px;
        width: 100%;
        height: 200px;
        position: fixed;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.75);
        border: solid;
        border-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        text-align: center;
    }


    .modalContent > p{

        margin-top: 10px;
        font-size: 13px;
    }

    .modalContent > h1{
        font-size: 20px;
    }
}